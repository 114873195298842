<template>
  <div class="trademark">
    <input id="copy_content" type="text" value="" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"/>
    <section class="search-option">
      <span class="search_title active">手机号</span>
      <el-input  clearable placeholder="手机号" v-model="trademarkObject.contactPersonPhone" class="_one"/>

      <span style="margin-left: 20px" class="search_title">订单编号</span>
      <el-input  clearable placeholder="订单编号" v-model="trademarkObject.annuityOrderNumber" class="_one"/>

      <span style="margin-left: 20px" class="search_title">订单创建时间</span>
      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="trademarkObject.orderCreateTimeStartStr" type="date" placeholder="选择时间"/>
      <i style="margin-right: 5px" class="el-icon-minus"/>
      <el-date-picker value-format="yyyy-MM-dd" class="el-date-editor-long-officialt one" v-model="trademarkObject.orderCreateTimeEndStr" type="date" placeholder="选择时间"/>

      <span style="margin-left: 20px" class="search_title">案卷号</span>
      <el-input  clearable placeholder="案卷号" v-model="trademarkObject.businessCaseId" class="_one"/>

      <span style="margin-left: 20px" class="search_title">申请号</span>
      <el-input  clearable placeholder="申请号" v-model="trademarkObject.patentApplyNo" class="_one"/>

      <span style="margin-left: 20px" class="search_title">任务号</span>
      <el-input  clearable placeholder="任务号" v-model="trademarkObject.businessTaskId" class="_one"/>

      <div class="function">
        <el-button style="width: 85px;border-radius: 10px;text-align: center" @click="resetCondtion">清空</el-button>
        <el-button type="primary" style="margin-right: 59px;width: 85px;border-radius: 10px;text-align: center;margin-left: 20px" @click="current = 1;getList()">筛选</el-button>
      </div>
    </section>

    <div class="monitoring_table">
      <vxe-grid
        stripe
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="650px"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="center"
        header-align="center"
        ref='xGrid'
        :seq-config="{startIndex: (current - 1) * size}"
        v-bind="gridOptions"
        @cell-click="cellClick"
        @clear-filter="filterChange"
        @filter-change="filterChange"
        @sort-change="filterChange"
      >

        <template #bsNode="{ row }">
          <span>{{queryBsTaskStatus(row.bsTaskStatus)}}</span>
        </template>

        <template #lanternfishNode="{ row }">
          <span>{{queryRenewalOrderStatus(row.annuityOrderStatus)}}</span>
        </template>

        <template #operation="{ row }">
          <div >
            <el-button v-if="row.officialReceiptUrl" type="text" size="medium" @click.native.stop="adminLists(row)" >
              <p style="display: flex;align-items: center">
                <img style="width: 20px;" src="../../assets/images/hostingPLatform/shou.png" alt="">
                查看收据
              </p>
          </el-button>
          </div>
        </template>
        <!--        详情-->
        <template #num1_default="{row}">
          <div  style="display: flex;background: #F3F9FE;align-items: center" class="alert-message">
            <!--查看详情-->
            <ul class="translate" cellspacing="0"
                style="margin-top: 15px;width: calc(100% - 120px - 2%);margin-left: 2%">
              <li style="background: #D9E2F1;height: 28px;border-radius: 8px 8px 0px 0px;">
                <!--日期-->
                <p class="sbmc" style="text-align: left">
                  <span>专利名称：</span>
                  <span style="cursor: pointer" @click="copys(detailList.patentName)">{{detailList.patentName}}</span>
                </p>

                <!--订单编号-->
                <p style="text-align: left">
                  <span>专利申请日：</span>
                  <span style="cursor: pointer" @click="copys(detailList.applicationDate)">{{detailList.applicationDate}}</span>
                </p>

                <!--翻译类型-->
                <p style="text-align: left">
                  <span>专利年费：</span>
                  <span style="cursor: pointer" @click="copys(detailList.annualBasicFee)">{{detailList.annualBasicFee}}</span>
                </p>

                <p style="text-align: left">
                  <span>代理费：</span>
                  <span style="cursor: pointer" @click="copys(detailList.agentFee)">{{detailList.agentFee}}</span>
                </p>
              </li>

              <li style="height: 28px">
                <!--日期-->
                <p style="text-align: left">
                  <span>专利申请号：</span>
                  <span style="cursor: pointer" @click="copys(detailList.patentApplyNo)">{{detailList.patentApplyNo}}</span>
                </p>

                <!--订单编号-->
                <p style="text-align: left">
                  <span>缴纳年度：</span>
                  <span style="cursor: pointer" @click="copys(detailList.patentYear)">{{detailList.patentYear}}</span>
                </p>

                <!--翻译类型-->
                <p style="text-align: left">
                  <span>滞纳金：</span>
                  <span style="cursor: pointer" @click="copys(detailList.annualLateFee)">{{detailList.annualLateFee}}</span>
                </p>

                <!--字数-->
                <p style="text-align: left">
                  <span>权利恢复费：</span>
                  <span style="cursor: pointer" @click="copys(detailList.patentRestorationFee)">{{detailList.patentRestorationFee}}</span>
                </p>
              </li>

              <li style="background: #D9E2F1;height: 28px;border-radius: 8px 8px 0px 0px;">
                <!--日期-->
                <p style="text-align: left">
                  <span>专利类型：</span>
                  <span style="cursor: pointer" @click="copys(detailList.patentType == 0 ? '发明' : detailList.patentType == 1 ? '实用新型' : '外观设计')">{{detailList.patentType == 0 ? "发明" : detailList.patentType == 1 ? "实用新型" : "外观设计"}}</span>
                </p>

                <!--订单编号-->
                <p style="text-align: left">
                  <span>缴纳期限：</span>
                  <span style="cursor: pointer" @click="copys(detailList.deadLine)">{{detailList.deadLine}}</span>
                </p>

                <!--翻译类型-->
                <p style="text-align: left">
                  <span>专利费减：</span>
                  <span style="cursor: pointer" @click="copys(detailList.annualFeeReduction == 0 ? '' : detailList.annualFeeReduction+ '%')"> {{ detailList.annualFeeReduction == 0 ? "" : detailList.annualFeeReduction+"%" }}</span>
                </p>
                <!--字数-->
                <p style="text-align: left">
                  <span>支付金额：</span>
                  <span style="cursor: pointer" @click="copys(detailList.annualFeeTotal)">{{detailList.annualFeeTotal}}</span>
                </p>
              </li>

              <li style="height: 29px"></li>
            </ul>
          </div>
        </template>
        <template #pager>
          <vxe-pager
            border
            background
            :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
            :current-page.sync="current"
            :page-size.sync="size"
            :total="total"
            @page-change="handleCurrentChange">
          </vxe-pager>
        </template>
        <!--自定义空数据模板-->
        <template #empty>
          <div>
            {{$t('personal_lang1.noDate')}}
          </div>
        </template>
      </vxe-grid>
    </div>
    <template>
      <el-dialog :visible.sync="dialogVisibles" :close-on-click-modal="false" custom-class="login_dialog" :before-close="closeEvent">
        <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"/>
      </el-dialog>
    </template>
    <el-dialog custom-class="_softwareUrl" style="align-items: center" title="查看文件" :visible.sync="dialogsoftwareUrlVisible">
      <img style="width: 500px" :src="queryDataUrl(this.fileUrl)" alt="">
    </el-dialog>
  </div>
</template>

<script>
  import classify from "@/components/query/public/classInTheNiceClassification";
  import login from "@/components/common/login"
  import moment from 'moment'
  import {customStart} from "@/request/trademarkPayment";
  import $ from "jquery";

  export default {
    components: { classify, login },
    data() {
      return {
        fileUrl: '',
        dialogsoftwareUrlVisible: false,
        detailList: [],
        //获取选中值
        valueArr: [],
        trademarkObject:{
          contactPersonPhone: '',
          annuityOrderNumber: '',
          patentApplyNo:	'',
          orderCreateTimeStartStr: '',
          orderCreateTimeEndStr: '',
          businessTaskId: '',
          businessCaseId: ''
        },
        options: [
          {
            value: '已注册',
            label: '已注册'
          }, {
            value: '无效',
            label: '无效'
          }, {
            value: '公告中',
            label: '公告中'
          }, {
            value: '驳回复审审查中',
            label: '驳回复审审查中'
          },{
            value: '异议审查中',
            label: '异议审查中'
          },{
            value: '注册申请审查中',
            label: '注册申请审查中'
          },{
            value: '撤销/无效宣告申请审查中',
            label: '撤销/无效宣告申请审查中'
          },{
            value: '其他情形',
            label: '其他情形'
          },{
            value: '无效',
            label: '无效'
          },{
            value: '已注册',
            label: '已注册'
          },{
            value: '公告中',
            label: '公告中'
          },{
            value: '审查中',
            label: '审查中'
          }],
        //分页信息
        current: 1,
        size: '50',
        tableConfig:{
          size: '20',
          current: 1,
        },
        total: 0,
        //表格过滤使用
        isImage: '',
        appnos: '',
        countryNames: '',
        intCls: '',
        cnApplicant: '',

        //表格展示配置
        gridOptions: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          customConfig: {
            storage: true,
            checkMethod: this.checkColumnMethod
          },
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            height: '850px',
            stripe: true
          },
          columns: [
            {
              type: 'seq',
              title: '序号',
              width: '50',
              fixed: 'left'
            },
            {
              field: 'contactPersonPhone',
              title: '手机号',
              width: '100'
            },
            {
              field: 'annuityOrderNumber',
              title: '订单编号',
              width: '180'
            },
            {
              field: 'orderCreateTime',
              title: '订单创建时间',
              width: '150'
            },
            {
              field: 'businessCreateTime',
              title: 'BS任务创建时间',
              width: '100'
            },
            {
              field: 'registerDate',
              title: '蓝灯鱼订单节点',
              width: '100',
              slots: {
                default: 'lanternfishNode'
              }
            },
            {
              field: 'annualFeeDate',
              title: 'BS节点',
              width: '100',
              slots: {
                default: 'bsNode'
              }
            },
            {
              field: 'businessCaseId',
              title: '案卷号',
              width: '100'
            },
            {
              field: 'businessTaskId',
              title: '任务号',
              width: '60'
            },
            {
              field: 'showAdress', title: '操作', visible: true,
              fixed: "right",
              width: '130',
              slots: {
                default: 'operation'
              }
            },
            {
              field: 'businessRemark',
              title: '备注'
            },
            {
              type: 'expand', title: '', visible: false, width: 160, slots: {
                // 使用插槽模板渲染
                content: 'num1_default',
              }
            },
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //总次数
        totalTimes: '',
        //使用次数
        usageTimes: '',
        //剩余次数
        remainTimes: '',

        //新增监控dialog
        monitor: false,
        //新增监控或修改监控 0-新增 1-修改
        addOrEdit:0,
        addOrEditData:{
          id:"",
          countrySet:[],
          niceSet:"",
          trademarkName:"",
          wordImageUrl:"",
          iconImageUrl:"",
          remarks:"",
          monitorType:1,
          sourctType:0,
          revokeThreeFlag:undefined,
          renewalFlag:undefined,
          trademarkApplyNo:undefined,
          trademarkOwnFlag:undefined,
          renewalNiceCode:undefined,
          applyPersonName:undefined
        },
        containCN:false,
        beforeEditCountryListNum:0,
        //申请人联想
        applyPersonNameArr:[],
        //国家筛选
        filterText: '',
        //国家树形表配置
        defaultProps: {
          children: 'children',
          label: 'label',
          id: 'code'
        },
        //上传图片header
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },
        //是否禁止新增监控(防止多次提交使用)
        isDisabled: false,
        //类别选择浮窗
        niceVisible: false,
        //商标监控种类选择-0 商标续展种类选择-1
        monitorCategoryOrRenewalCategory:0,
        //登录浮窗
        dialogVisibles: false,
        dialogClose: false,
        applicantName: '',
        custodyTypeSet: 'trademark',
        summaryData:[],
        multipleSelection:[]
      }
    },

    async mounted() {
      //数据初始化
      await this.init()
      // this.editeApplicantName()
    },
    watch:{
      monitor(newVal, oldVal){
        if(newVal){
          this.multipleSelection = []
          this.$refs.trademarkRenewalPlatformPage.clearSelection();
        }
      }
    },
    methods: {
      copys(text, value) {
        if (text) {
          //获取要赋值的input的元素
          var inputElement = document.getElementById("copy_content");
          //给input框赋值
          inputElement.value = text;
          //选中input框的内容
          inputElement.select();
          // 执行浏览器复制命令
          document.execCommand("Copy");
          if (value) {
            this.$axios.post('/common/query/getNoticePages', {
              trialNum: text.toString(),
              "beginPage": 1,
              "endPage": 100
            }).then((data) => {
              return;
            })
          }
          //提示已复制
          this.$message('已复制');
        } else {
          //提示已复制
          this.$message('暂无数据');
        }
      },
      seqMethod({ rowIndex }) {
        rowIndex = (rowIndex + 1) + (this.current - 1) * this.current
        return rowIndex
      },
      adminLists(row,val){
        this.fileUrl = row.orderImageUrl
        window.open(this.fileUrl)
      },
      adminList(row,val){
        this.$axios.post("/custody/business/file/record/admin/list", {
          orderNumber: row.annuityOrderNumber,
          orderCategory: 'trademark',
          fileType: val
        }).then(({data}) => {
          if(data.code == 0){
            this.fileUrl = data.data[0].fileUrl
            window.open(this.fileUrl)
          }
        })
      },
      queryBsTaskStatus(data){
        let str = ''
        switch (data) {
          case 0 :  str = '待发起'; break;
          case 20 : str = '提交文件中'; break;
          case 30 : str = '提交官费中'; break;
          case 40 : str = "等待官方收据"; break;
          case 50: str = "订单完成"; break;
          default : str = ''; break;
        }
        return str
      },
      queryRenewalOrderStatus(data){
        let str = ''
        switch (data) {
          case 0 :  str = '待支付'; break;
          case 10 : str = '支付成功'; break;
          case 20 : str = '提交文件中'; break;
          case 30 : str = "提交官费中"; break;
          case 40: str = "等待官方收据"; break;
          case 50: str = "订单完成"; break;
          case 60: str = "退款成功"; break;
          default : str = ''; break;
        }
        return str
      },
      checkColumnMethod({column}) {
        if (['nickname', 'role'].includes(column.property)) {
          return false
        }
        return true
      },
      toggleMethod({expanded, column, columnIndex, row, rowIndex}) {
        if (expanded == true) this.$refs.xGrid.clearRowExpandLoaded(row)
        return true
      }
      ,
      loadMethod({row}) {
        // 模拟后台接口
        return new Promise(resolve => {
          this.$axios.get("/custody/annuity/order/id/" + row.annuityOrderId).then(({data}) => {
            if(data.code == 0){
              this.detailList = data.data
              resolve()
            }
          })
        })
      },
      noEnable({row, rowIndex}){
        if(row.existFlag) return 'existFlag'
      },
      //一键代缴
      allPayment(){
        let data = this.$refs.xGrid.getCheckboxRecords()
        if(data.filter(item => item.annualFeeType).length != data.length) {
          this.$message.error('您选中的部分商标暂时无需进行续展，请您重新选择');
          return;
        };

        this.$axios
          .post("/custody/trademark/renewal/payment/order",{
            trademarkRenewalIdSet:  data.map(item => item.trademarkRenewalId)
          })
          .then(({data}) => {
            if(data.code == 0){
              this.$router.push({
                path: '/hostingPlatforms/platformPay',
                query: {
                  order: 'order',
                  renewalOrderNumber: data.data.annuityOrderNumber,
                  type: 'tradmark',
                  applicantAddress: data.data.applicantAddress,
                  applicantName: data.data.applicantName
                }
              })
            }
          })
      },
      tableRowClassNames({row, rowIndex}) {
        if(row.trademarkStatus == '无效'){
          return 'color-row1-1';
        }
      },
      //表格单元格点击
      cellClick({
                  row,
                  rowIndex,
                  $rowIndex,
                  column,
                  columnIndex,
                  $columnIndex,
                  triggerRadio,
                  triggerCheckbox,
                  triggerTreeNode,
                  triggerExpandNode,
                  $event
                }) {
        this.$refs.xGrid.toggleRowExpand(row)
      }
      ,
      editeApplicantName(){
        this.$axios.get(
          '/custody/applicant/list').then(({data}) => {
          if(data.code == 0){
            this.applicantName = data.data[0].applicantName
          }
        })
      },
      selectable(row, index) {
        if(row.existFlag){
          return false
        }else {
          return true
        }
      },
      //取消代理
      cancelProxy(){
        let data = this.$refs.xGrid.getCheckboxRecords()
        let arr = []
        if(data.length > 0){
          this.$axios
            .post("/custody/trademark/renewal/modify/manage", {
              renewalFlag: false,
              renewalAnnualFlag: '',
              trademarkRenewalIdSet: data.map(item => item.trademarkRenewalId)
            }).then(({data}) => {
            if(data.code == 0){
              this.$message({
                message: data.data,
                type: 'success'
              });
              this.getList()
            }else {
              this.$message.error('取消代理失败');
            }
          }).catch(err => {
            this.$message.error('取消代理失败');
          })
        }else {
          this.$message.error('请选择需要取消代理的商标');
        }
      },
      //委托代缴
      entrustedPayment(row){
        this.$axios
          .post("/custody/trademark/renewal/payment/order",{
            trademarkRenewalIdSet: row.trademarkRenewalId.split(";")
          })
          .then(({data}) => {
            if(data.code == 0){
              this.$router.push({
                path: '/hostingPlatforms/platformPay',
                query: {
                  order: 'order',
                  renewalOrderNumber: data.data.annuityOrderNumber,
                  type: 'tradmark',
                  applicantAddress: data.data.applicantAddress,
                  applicantName: data.data.applicantName
                }
              })
            }
          })
      },
      //年费已缴
      annualFeePaid(item){
        this.$confirm('请您确认该商标的年费是否已经缴纳，若确认无误，我们将开启该商标下一次的监控', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios
            .post("/custody/trademark/renewal/modify/manage", {
              renewalFlag: '',
              renewalAnnualFlag: true,
              trademarkRenewalIdSet: [item.trademarkRenewalId]
            }).then(({data}) => {
            if(data.code == 0){
              this.$message({
                message: data.data,
                type: 'success'
              });
              this.getList()
            }else {
              this.$message.error('年费已缴失败');
            }
          }).catch(err => {
            this.$message.error('年费已缴失败');
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消年费已缴'
          });
        });

      },
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      getRowKeys(row) {
        return row.platformTmId
      },
      save(){
        this.multipleSelection.push({
          applicantName: this.summaryData.applicantName,
          custodyTypeSet: [],
          trademarkRenewalPlatformList: this.valueArr
        })
        this.multipleSelection.map(item => {
          if(item.patentPlatformList) item.custodyTypeSet.push('patent')
          if(item.trademarkRenewalPlatformList) item.custodyTypeSet.push('trademark')
          if(item.softwarePlatformList) item.custodyTypeSet.push('software')
          if(item.certificatePlatformList) item.custodyTypeSet.push('certificate')
          return item
        })

        this.multipleSelection = this.multipleSelection.filter(item => item.patentPlatformList || item.trademarkRenewalPlatformList || item.softwarePlatformList || item.certificatePlatformList)

        if(this.multipleSelection.length == 0){
          this.$message.error('请选择要保存的知识产权托管数据');
          return;
        }
        this.$axios
          .post("/custody/home/save/update/custody", this.multipleSelection[0]).then(({data}) => {
          if(data.code == 0){
            this.$message({
              message: data.data,
              type: 'success'
            });
            this.monitor = false
            this.current = 1
            this.getList()
          }else {
            this.$message.error('知识产权托管数据保存失败');
          }
        }).catch(err => {
          this.$message.error(err.data.msg);
        })
      },
      //多选的方法
      handleSelectionChange(val) {
        this.valueArr = val
      },
      summaryApplicant(){
        this.$axios
          .post("/custody/home/applicant/info/summary", {
            applicantNameSet: [this.applicantName],
            custodyTypeSet: [this.custodyTypeSet],
            current: this.tableConfig.current,
            size: this.tableConfig.size
          },{timeout: 60 * 1000 * 5}).then(({data}) => {
          if(data.code == 0){
            this.summaryData = data.data[0]
          }
        })
      },
      onTypeBlur(e) {
        if (e.target.value) {
          // this.type = e.target.value;
          // 如果是对象，要使用this.$set方法
          this.$set('addOrEditData', 'applyPersonName', e.target.value)
        }
      },
      uploadChangeFn(file, fileList) {
        let imgSize = Number(file.size / 1024 / 1024);

        if (imgSize > 3) {
          this.$message('文件大小不能超过3MB，请重新上传');
          this.materialList[scope.$index].fileList.splice(scope.index, 1);
          return;
        }
      },
      optionEvent(val){
        this.addOrEditData.applyPersonName = val

      },
      remotePeopleMethod(val){
        this.addOrEditData.applyPersonName = val
        if(this.addOrEditData.countrySet.length > 0){
          this.applicantHit(val)
        }else {
          this.$message('为了为您提供更准确地结果，请您先选择国家/地区');
        }
      },
      //根据国家联想申请人名称
      applicantHit(val){
        let arr = []
        this.addOrEditData.countrySet.map(item => {
          arr.push(this.searchCountryCodeByName(item))
        })
        this.$axios
          .post("/common/query/applicantHit",{
            country:arr.join(';'),
            applicant: val
          }).then(({data}) => {
          this.applyPersonNameArr = data
          return;
        })
      },
      handleCheckChange(){
        this.confirm()
      },
      //判断用户是否登录状态
      async init() {
        //若已经登录，则获取监控列表
        if (this.tool.getCookie("auth")) {
          await this.getList()
        }
        //若未登录，则跳出登录框
        else { this.dialogVisibles = true }
      },

      //获取商标监控列表
      getList() {
        if(!(this.trademarkObject.trademarkStatusSet instanceof Array)){
          this.trademarkObject.trademarkStatusSet = this.trademarkObject.trademarkStatusSet ? this.trademarkObject.trademarkStatusSet.split(";") :[]
        }

        this.$axios.post("/custody/business/query/annuity/order/task/for/admin/page", {...this.trademarkObject, current: this.current, size: this.size})
          .then(({data}) => {
            if (data.code == 0) {
              if (data.data.records.length) {
                this.gridOptions.data = data.data.records
                this.total = data.data.total
                this.size = data.data.size
              }
              else {
                this.gridOptions.data = []
              }
            }
          })
          .catch( error => {console.log(error) })
      },
      //剩余监控个数
      async monitorRemaining() {
        await this.$axios
          .get("/product/servicemanage/getMonitorTimes").then(({data}) => {
            if (data.code == 0) {
              //总次数
              this.totalTimes = data.data.totalTimes
              //使用次数
              this.usageTimes = data.data.usageTimes
              //剩余次数
              this.remainTimes = data.data.remainTimes
            }
          })
      },

      //跳转
      skip(item) {this.$router.push({path: item})},
      //重置
      resetCondtion() {
        //监控商标
        this.trademarkObject = {
          //申请号
          trademarkApplyNo: '',
          //否商标名称
          trademarkName: '',
          //否尼斯分类的code
          renewalNiceCode: '',
          //否商标状态
          trademarkStatusSet: '',
          //否申请人名称
          applicantName: '',
          //否年费开始时间 yyyy-MM-dd
          annualFeeStartDateStr: '',
          //否年费结束时间 yyyy-MM-dd
          annualFeeEndDateStr: ''
        },
          this.current = 1
        this.getList()
      },

      //获取展示表格cellClassName(图片列新增class=col-orange)
      cellClassName({row, column, $columnIndex}) {
        if ($columnIndex == 1) {
          return 'col-orange'
        }
      },
      //本月到期的数据的行颜色高亮
      rowStyle({row, rowIndex, $rowIndex}) {
        if (row.endDate && this.getDates(row.endDate)) {
          return 'background: #fff3e0'
        }
      },

      //获取当前年月日
      getDates(value) {
        //获取当年月日时分秒 2022-09-26 14:05:59
        if(!value) return
        if (value.split('-')[0] == moment().format('YYYY-MM-DD').split('-')[0] && value.split('-')[1] == moment().format('YYYY-MM-DD').split('-')[1]) {
          return true
        }
        else {
          return false
        }
      },

      //表格过滤变化触发
      filterChange() {
        let getCheckedFilters = this.$refs.xGrid.getCheckedFilters()
        this.trademarkObject = {
          //申请号
          trademarkApplyNo: '',
          //否商标名称
          trademarkName: '',
          //否尼斯分类的code
          renewalNiceCode: '',
          //否商标状态
          trademarkStatusSet: '',
          //否申请人名称
          applicantName: '',
          //否年费开始时间 yyyy-MM-dd
          annualFeeStartDateStr: '',
          //否年费结束时间 yyyy-MM-dd
          annualFeeEndDateStr: ''
        },

          this.current = 1

        getCheckedFilters.map(item => {
          switch (item.property) {
            //类别
            case "trademarkName":
              this.trademarkObject.trademarkName = item.datas[0]
              break;
            //监控申请人
            case "renewalNiceCode":
              this.trademarkObject.renewalNiceCode = item.values.join(';')
              break;
            //监控类型
            case "trademarkApplyNo":
              this.trademarkObject.trademarkApplyNo = item.datas[0]
              break;
            //注册号
            case "trademarkStatus":
              this.trademarkObject.trademarkStatusSet = item.values.join(';')
              break;
            //国家/地区
            case "applicantName":
              this.trademarkObject.applicantName = item.datas[0]
              break;
            case "annualFeeDate":
              this.trademarkObject.annualFeeStartDateStr = item.datas[0][0];
              this.trademarkObject.annualFeeEndDateStr = item.datas[0][1];
              break;
          }
        })

        this.getList()
      },


      //新增商标监控
      add() {
        this.summaryApplicant()
        this.monitor = true
      },
      //给图片添加地址
      returnUrl(url) {
        if (url.startsWith('/group')) return "https://tmimage.lanternfish.cn"+url
        else return url
      },

      //查看监控结果详情
      viewMonitoring(id, cnApplicant, appno, imgUrl, cusCaseNo, country, row) {
        localStorage.setItem("imgUrl", imgUrl)
        localStorage.setItem("selectedcountry", country.split(";"))
        this.$router.push({
          path: "/monitoringResult",
          query: {
            step: 2,
            md: "t1_11",
            id: id,
            ca: cnApplicant,
            no: appno,
            youRef: cusCaseNo,
            country,
            content: row.isImage == "图片" ? row.image : row.tmcn
          }
        })
      },


      //表格页码变化触发
      handleCurrentChange({currentPage, pageSize}) {
        this.current = currentPage
        //获取列表数据
        this.getList()
      },

      //表格页码变化触发
      handleCurrentChanges(currentPage) {
        this.tableConfig.current = currentPage
        //获取列表数据
        this.summaryApplicant()
      },

      //新增或修改商标监控国家变化触发
      SelectCountryFn(val) {
        let arr = []
        if (val.length == 0) {
          this.$refs.tree[0].setCheckedKeys([])
          this.containCN = false
        }
        else {
          this.allCountry.map(item => {
            val.map(items => {
              if (item.city == items) {
                arr.push([item.code])
              }
              return
            })
            return
          })
          this.$refs.tree[0].setCheckedKeys(arr)

          let CN = false
          val.map(item=>{
            if (item == '中国大陆'){
              CN = true
            }
          })
          this.containCN = CN
          if (this.containCN == false){
            this.addOrEditData.revokeThreeFlag = undefined
            this.addOrEditData.renewalFlag = undefined
            this.addOrEditData.trademarkApplyNo = undefined
            this.addOrEditData.trademarkOwnFlag = undefined
            this.addOrEditData.renewalNiceCode = undefined
          }
        }
      },

      //新增或修改商标监控国家确定触发
      confirm() {
        this.addOrEditData.countrySet = []

        //this.$refs.tree[0].getCheckedKeys() 内存储树形结构选中项
        // 0 1 2 3 4 5 6 7表示 全选 亚洲 欧洲 北美洲 南美洲 非洲 大洋洲 世界知识产权组织
        // 其余则为国家简写 如 中国大陆->CN

        if (this.$refs.tree[0].getCheckedKeys().includes(0)) {
          //若存在 0 则表示全选，将所有国家/地区插入this.addOrEditData.countrySet
          this.allCountry.map(item => {
            this.addOrEditData.countrySet.push(item.city)
            return
          })
        }

        else {
          this.allCountry.map(item => {
            this.$refs.tree[0].getCheckedKeys().map(items => {
              if (item.code == items) {
                this.addOrEditData.countrySet.push(item.city)
                return
              }
            })
            return
          })
        }

        if (this.$refs.tree[0].getCheckedKeys().includes("CN")){
          this.containCN = true
        }
        else {
          this.containCN = false
        }

        // this.$refs.fuzzySearch.blur()
      },
      //新增监控选择国家过滤方法
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      //新增监控上传图片成功
      uploadFn(response) {
        if (response.code == 0){
          this.addOrEditData.iconImageUrl = response.data.url
        }
        else this.$message('图片上传失败')
      },

      //根据国家名称查询国家Code
      searchCountryCodeByName(countryName){
        let s = ""
        this.allCountry.map(item=>{
          if (item.city == countryName){
            s = item.code
          }
        })
        return s
      },

      close() {
        this.niceVisible = false
        this.monitor = true
      },
      classifyType(data) {
        if (this.monitorCategoryOrRenewalCategory == 0){
          this.addOrEditData.niceSet = []
          data.forEach(item=>{
            this.addOrEditData.niceSet.push(item.no)
          })
        }
        else {
          if (data.length > 1){
            this.$message("此处仅可以选择一个类别")
            return
          }
          else {
            this.addOrEditData.renewalNiceCode = data[0].no
          }
        }
      },

      // 登录弹层隐藏
      loginDialog(data) { this.dialogVisibles = data },
      dialogCloseBool(data) { this.dialogClose = data },
      closeEvent(done) {
        this.dialogClose = true
        done()
      },
    },
  }
</script>

<style lang="less">
  .trademark {
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }

    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }

    .vxe-table--header {
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }

    .color-row1-1{
      background: #F5F7FA;
    }


    .vxe_grid_header {
      border-radius: 15px 15px 0px 0px !important;
      overflow: hidden !important;
      background: #E2EFFF !important;
    }

    .row--current, .row--hover {
      background: #ffffff !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }

    .col-orange div {
      width: 118px !important;
      display: flex !important;
      height: 48px !important;
      padding: 2px 0 !important;
      justify-content: center !important;
    }
  }
</style>

<style scoped lang="less">
  .el-select-dropdown__item {
    height: auto !important;
  }

  .el-tree {
    display: flex;
    flex-direction: column;
  }

  .translate {
    margin-right: 45px;

    li {
      display: flex;
      align-items: center;

      p {
        width: 25% !important;
        display: flex;
        align-items: center;

        span:nth-of-type(2) {
          width: 60%;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:nth-of-type(1) {
          padding-left: 20px;
          box-sizing: border-box;
        }

        &.sbmc {
          position: relative;

          &:before {
            width: 2px;
            height: 12px;
            content: '';
            left: 12px;
            top: 4px;
            display: block;
            position: absolute;
            background: #2B68D4;
          }
        }
      }
    }
  }

  section.search-option {
    min-height: 100px;
    background-color: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    /*border: solid 1px #e4e4e4;*/
    margin-bottom: 20px;
    padding-top: 23px;
    padding-left: 19px;
    /*height: 135px;*/
    padding-bottom: 20px;
    .function{
      display: flex;
      justify-content: end;
    }
    .search_title {
      font-size: 14px;
      color: #474747;
      margin-right: 14px;
      &.active{
        position: relative;
        padding-left: 16px;
        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 17px;
          background: #465CFC;
          left: 0;
          top: 2px;
        }
      }
    }

    div {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    .el-select-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;

      .el-input {
        input.el-input__inner {
          border: 1px solid #cccccc;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .one, .el-input {
      width: 180px;

      input {
        height: 34px !important;
        border-color: #ccc;
        color: inherit;
        padding: 0 4px;
      }
    }

    ._one /deep/ .el-input__inner {
      height: 34px;
    }

    .el-date-editor-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;
      line-height: 32px;

      input.el-input__inner {
        border: 1px solid #cccccc;
        text-indent: 42px;
      }
    }
  }

  .trademark {
    .danTitle {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #999999;
      font-weight: 600;
      position: relative;
      margin-left: 17px;

      &:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 16px;
        top: 17px;
        background: #1a3dbf;
      }

      span {
        margin: 0 9px;

        &:nth-of-type(1) {
          margin-left: 18px;
        }

        &:nth-of-type(3) {
          color: #506bce;
        }
      }

      span:hover {
        cursor: pointer;
        color: #506bce;
      }
    }

    box-sizing: border-box;

    .monitoring_top {
      height: 131px;
      border: 1px solid #e4e4e4;
      background: #fff;
      margin: 15px auto;

      .condition {
        padding: 45px 0 0 45px;

        .el-input__inner {
          height: 36px;
        }

        .el-input {
          width: 220px;
          margin-right: 20px;
          padding: 0px;
          margin-left: 10px;
        }
      }
    }

    .monitoring_two {
      width: 100%;
      margin: auto;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 9px;
      justify-content: space-between;
      .monitoring_two_btn{
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .totalMonitoring {
        background: #f4f7ff;
        font-size: 14px;
        color: #383838;
        width: 354px;
        height: 44px;
        border-radius: 20px;
        margin-right: 32px;
        display: flex;
        align-items: center;
        padding: 0 37px 0 33px;
        justify-content: space-between;
        box-sizing: border-box;

        i {
          font-size: 16px;
        }

        .active1 {
          color: #3057e3;
        }

        .active2 {
          color: #ff8d1a;
        }
      }

      .jg {
        width: 14px;
        cursor: pointer;
        position: absolute;
        margin-left: 0px;
        margin-right: 14px;
      }

      .recommend-tips {
        vertical-align: bottom;
        width: 425px;
        padding: 7px 12px;
        line-height: 17px;
        background: #fbedec;
        border: 1px solid #f6d4d4;
        font-size: 12px;
        text-align: left;

        &:before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          border-bottom: 1px solid #f6d4d4;
          border-left: 1px solid #f6d4d4;
          top: 50%;
          left: -7px;
          transform: translateY(-50%) rotate(45deg);
          background: #fbedec;
        }
      }

      .addMsg {
        position: absolute;
        left: 110px;
        top: -18px;
      }

      .add {
        width: 152px;
        height: 32px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        text-align: center;
        line-height: 30px;
        color: #2c3e50;
        cursor: pointer;
        margin-top: 30px;
      }

      .jum {
        width: 120px;
        margin-left: 15px;
      }

      .add:hover {
        background: #193dbe;
        color: #fff;
        border: 1px solid #193dbe;
      }

      .batchImport {
        float: right;
        margin-top: 35px;

        span {
          margin-right: 15px;
          vertical-align: middle;

          img {
            vertical-align: middle;
          }

          .upload-demo {
            .el-upload-dragger {
              width: 100px;
              height: auto;
              background: none;
              border: none;
              margin-top: 3px;
            }

            .el-upload-list {
              display: none;
            }
          }
        }

        .icondaoru-tianchong {
          font-weight: bold;
          vertical-align: middle;
          color: #de5455;
          margin-right: 3px;
          font-size: 18px;
        }
      }
    }

    .monitoring_table {
      padding: 13px 19px;
      background: #FFFFFF;
      border-radius: 8px;
      /deep/.el-button--primary.is-plain {
        color: #ffffff;
      }
    }

    .seeResults:hover {
      text-decoration: underline;
    }

    .primary {
      background: #d01027;
      border: #d01027;
    }

    .primary:hover {
      background: #d73e3f;
    }

    .can:hover {
      color: white;
      background-color: #d01027;
      border: 1px solid #d01027;
    }

    .fyBlock {
      padding-bottom: 1%;
      padding-top: 2%;
      text-align: center;
    }

    .monitor {

      .prompt {
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #e4e4e4;
        margin: 0 32px;
        text-align: center;

        .text {
          font-size: 14px;
          color: #222222;
          vertical-align: middle;
          margin-left: 10px;
        }
      }

      .mainBody {
        width: 495px;
        margin: 0px auto 0px;

        .check {
          margin-bottom: 20px;

          .requiredStar {
            color: #d01027;
            font-weight: bold;
            font-size: 16px;
          }

          .title:nth-child(1) {
            width: 105px;
            font-size: 14px;
            color: #222222;
            text-align: left;
            vertical-align: middle;
          }

          .title {
            display: inline-block;
          }

          .right {
            width: 380px;
            text-align: left;
            vertical-align: middle;
            position: relative;
            display: inline-block;

            .upload-drag {
              height: 82px !important;

              &.uploadDisabled {
                position: relative;

                .el-upload {
                  .el-upload-dragger {
                    .el-upload-disabled-shade {
                      display: block;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      top: 0;
                      right: 0;
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }

            .el-upload-dragger {
              border: 1px solid #e4e4e4;
              height: 82px;
              width: 380px;
              cursor: pointer;
            }

            .upload-img {
              height: 96%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

            p.delete {
              position: absolute;
              z-index: 5;
              right: 10px;
            }

            p {
              margin-top: 12px;
              font-size: 12px;
              letter-spacing: 1px;
              color: #999999;

              &.delete:hover {
                color: #0b6ce8;
              }
            }

            .leftText {
              height: 36px;
              border-color: #e4e4e4;
            }

            .el-input-long-officialt {
              width: 380px;
              background-color: #fff;

              &.active {
                width: 360px;
              }
            }

            .rightText {
              float: right;
              border-left: 1px solid #ccc;
              width: 40px;
              text-align: center;
            }

            .el-select-long-officialt {
              width: 380px;
              background-color: #fff;

              .el-input__suffix-inner {
                margin-top: 9px;
              }
            }

            .result {
              margin: 10px 0 0;

              .resultImg {
                width: 82px;
                height: 82px;
                border: 1px solid #e4e4e4;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                float: left;
              }

              .resultText {
                float: left;
                margin-left: 16px;

                span {
                  color: #222;
                  font-size: 14px;
                }
              }
            }
          }

          .niceCode {
            width: 380px;
            border: 1px solid #e4e4e4;
            height: 38px;
            border-radius: 3px;

            .leftText {
              border: transparent;
              width: 310px;

              input.el-input__inner {
                border: none !important;
              }
            }

            .rightText {
              width: 60px;
              height: 25px;
              margin-top: 9px;
            }
          }

          .el-select {
            .el-select__tags {
              .el-select__input {
                border: none !important;
              }
            }
          }
        }
      }

      .dialog-footer {
        float: right;
        margin-right: 113px;

        .submit {
          width: 90px;
          height: 36px;
          display: inline-block;
          background-color: #d01027;
          color: #fff;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          margin-top: 22px;
          margin-left: 32px;
          cursor: pointer;
          border-radius: 3px;
        }

        .submit:hover {
          background-color: #d73e3f;
        }
      }
    }
  }

  .softness_dialog{
    box-sizing: border-box;
    /deep/.el-dialog__body{
      padding: 0 22px;
      box-sizing: border-box;
    }
    /deep/.el-dialog{
      border-radius: 8px;
    }
    .monitor_title{
      font-size: 16px;
      font-weight: 500;
      color: #2E54D9;
      padding: 14px 0 0px 34px;
    }
    .monitor_clearfix{
      margin-top: 19px;
      width: 676px;
      height: 440px;
      background: #EFF2FD;
      border-radius: 12px;
      border: 1px solid #E1E7F2;
      padding: 16px 0 0 20px;
      box-sizing: border-box;
      .table_title{
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        position: relative;
        padding-left: 7px;
        &::before{
          position: absolute;
          width: 3px;
          height: 18px;
          background: #465CFC;
          content: '';
          left: 0;
          top: 4px;
        }
      }
      .el-table{
        margin-top: 14px;
        border-radius: 6px 6px 0px 0px;
        /deep/.existFlag td{
          background: #F5F7FA;
        }
      }
    }
  }
</style>
