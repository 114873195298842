<template>
  <section class="classify class-in-the-Nice-classification" :class="{en:$i18n.locale=='en-US','cn-new':$i18n.locale=='zh-CN'}">
    <div>
      <ul>
        <li v-for="(item,index) in classify" @click="classifyChange(item)" :class="{checked:item.isCheck}">
          <el-tooltip class="no" effect="light" :content="$i18n.locale=='en-US'?item.nameEn:item.name" placement="top">
            <span class="nice-no">{{item.no}}</span>
          </el-tooltip>
        </li>
      </ul>

      <p class="under-ul-line"/>

      <div class="buttons">
        <el-checkbox v-model="othersItem.checkAll" @change="checkedAllFn()">
          {{$t('query_lang.checkAll')}}
        </el-checkbox>

        <el-button @click="classifyConfirm()" class="niceConfirm el-long-defalut-button">
          {{$t('query_lang.confirm')}}
        </el-button>

        <el-button plain @click="classifyReset()" class="niceClose el-long-white-button resetButtons" style="margin-right: 20px">
          {{$t('follow_up_FE_lang.reset')}}
        </el-button>
      </div>
    </div>
  </section>
</template>

<script>
  /**
   * 请在引用处控制显隐,如<classify v-show="queryItemsShow.isShow.classify" class="classify"></classify>
   * 请在使用处,根据需要设置定位section.classify{position: absolute;};
   * **/
  import { classifyData } from '../../../components/combinedQuery/data'
  import { mapState, mapMutations, mapGetters } from 'vuex';
  export default {
    name: "class-in-the-Nice-classification",
    data() {
      return {
        classify:classifyData
      }
    },
    mounted() {
      this.classifyChange();
    },
    methods: {
      ...mapMutations('query', ['setClassifyShowFn','queryItemsShowFn', 'queryDataFn', 'ajaxResponseFn', 'othersItemFn', 'pageNum_Fn', 'pageSize_Fn']),

      classifyChange(item) {
        if(item){
          item.isCheck = !item.isCheck;
          item.isQuery = item.isCheck;
        }

        if (this.queryData.classify.filter((item2) => item2.isCheck == false).length){
          this.othersItemFn({checkAll: false})
        }
        else {
          this.othersItemFn({checkAll: true})
        }

        this.queryDataFn()//刷新vuex工具,以便可视及时更新
      },

      checkedAllFn() {
        this.classify.map((item) => {
          item.isCheck = true
          item.isQuery = true
        })
      },

      classifyCancel() {
        this.queryItemsShowFn({
          isShow:{
            classify: false,
            upload: false,
            progressBar: false,
          }
        })

        this.$emit("closeFn");
      },

      classifyReset(){
        this.othersItem.checkAll = false

        this.classify.map((item) => {
          item.isCheck = false
          item.isQuery = false
        })
      },

      classifyConfirm() {
        this.$emit('classifyConfirm',this.classify.filter(item => item.isCheck))
        this.setClassifyShowFn()
        this.$emit("closeFn")
      },
    },
    computed: {
    ...mapState('query', ['queryItemsShow', 'queryData', 'ajaxResponse', 'othersItem', 'pageNum_', 'pageSize_']),
    ...mapGetters("query", ['classifyChoosed','classifyStatus']),
    }
  }
</script>

<style scoped lang="less">
  .class-in-the-Nice-classification {
    z-index: 2;
    width: 100%;
    text-align: left;
    & > div {
      width: 740px;
      padding: 35px 0 57px 0px;
      background-color: #ffffff;
      box-shadow: 0px 2px 18px 0px rgba(47, 47, 47, 0.18);
      border-radius: 5px;

      ul {
        height: 200px;
        overflow: auto;
        li {
          display: inline-block;
          width: 32px;
          height: 32px;
          line-height: 32px;
          border-radius: 50%;
          background: #ffffff;
          cursor: pointer;
          color: #333333;
          margin-bottom: 8px;
          letter-spacing: 1px;
          text-align: center;
          margin-left: 44px;
          &:hover {
            color: #193dbe;
          }
          &.checked {
            background: #193dbe;
            color: #ffffff;
          }
        }
        li:nth-of-type(5n+1){

        }
        li:nth-of-type(3n+1){
          &:after{
            display: block;
            width:10px;
            background: red;
            content:"";
          }
        }
      }
      .under-ul-line {
        height: 1px;
        width: 660px;
        margin: 0 auto;
        background: #eaeaea;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .buttons {
        margin-top: 15px;
        padding-right: 68px;
        overflow: hidden;
        .el-checkbox {
          margin-left: 40px;
        }
        .el-button {
          float: right;
        }

      }
      .resetButtons:hover{
        background-color: #193dbe;
        border-color: #193dbe;
        color:#fff;
      }
    }
  }
</style>
